<template>
	<b-card title="User List">
		<b-row class="mb-3">

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="name">Name:</label>
					<input 
						id="name"
						type="text" 
						class="form-control"
						placeholder="Search: name"
						v-model="filter.name" 
					>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="email">Email:</label>
					<input 
						id="email"
						type="text" 
						class="form-control"
						placeholder="Search: email"
						v-model="filter.email" 
					>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>Start Date:</label>
					<flat-pickr
						v-model="filter.start_date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
							locale: {
								rangeSeparator: ' - ',
							},
							maxDate: new Date(),
						}"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>End Date:</label>
					<flat-pickr
						v-model="filter.end_date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
							locale: {
								rangeSeparator: ' - ',
							},
							maxDate: new Date(),
						}"
					/>
				</div>
			</b-col>
			
			<b-col cols="12" class="mt-2">
				<button
					class="btn btn-outline-secondary mr-2"
					@click="clearFilter" 
				>
					Reset
				</button>
				<button
					class="btn btn-primary"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.form-modal-export-csv
					@click="getDataExport">
						Export
					</button>
			</b-col>

			<Export
				:is-loading="isLoadingExport"
				:result-export="resultExport"
				:export-now="exportNow"
			/>
		</b-row>
		
		<Table 
			:result="result"
			:user="user"
			:is-loading="isLoading"
			:get-data="getData"
			:format-date="formatDate"
			:current-page="currentPage"
		/>
	</b-card>
</template>

<script>
import Export from '@/components/ExportCsv.vue'
import Table from '@/components/user-login/Table.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { 
	BRow, 
	BCol, 
	BCard,
	BTable,
	BFormInput,  
	BButton, 
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Export,
		Table,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			// endDate: false,
			currentPage: 1,
			isLoading: false,
			result: [],
			user: {},
			link:'',
			isLoadingExport: false,
			// filter: {},
			// listAge: [],
			resultExport: [],
			// sort: '',
			// listProvince: [],
			// listCity: [],
			// gender: [
			// 	{ 
			// 		title: 'Wanita',
			// 		value: 'P'
			// 	}, 
			// 	{ 
			// 		title: 'Laki-laki',
			// 		value: 'L'
			// 	}, 
			// ],
			filter: {
				name: '',
				email: '',
				// city: '',
				// province: '',
				// gender: '',
				// range_age: '',
				start_date: '',
				end_date: '',
				// order_type: ''
			},
		}
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
		// "filter.province": function (data) {
		// 	if (data) {
		// 		if (data != this.filter.province) {
		// 		this.filter.city = "";
		// 		}
		// 		this.getCity(data);
		// 	}
		// }
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	created() {
		this.getData()
	},
	mounted() {
		// this.getListAge();
		// this.getProvince();
		// this.getCity();
	},
	methods: {
		// getFilter(value) {
		// 	this.filter.order_type = value.order_type
		// },
		submitFilter() {
			this.getData(this.currentPage)
		},
		getDataExport() {
			this.isLoadingExport = true
			this.$http.get('/admin/export/user-login/list'
			).then(response => {
				this.resultExport = response.data.data
				this.isLoadingExport = false
			}).catch(error => {
			})
		},
		clearFilter() {
			this.filter = {
				name: '',
				email: '',
				// city: '',
				// province: '',
				// gender: '',
				// range_age: '',
				start_date: '',
				end_date: '',
				// order_type: ''
			}
		},
		exportNow() {
			// this.isLoadingExport = true
			const exportParam = this.filter
			exportParam.per_page = null
			this.$swal({
						title: 'Sukses',
						icon: 'success',
						text: 'Mengexport data di background, mohon tunggu beberapa saat.',
						type: 'warning',
						customClass: {
							confirmButton: 'btn btn-primary',
						},
						buttonsStyling: false,
					}) 
			this.$bvModal.hide('form-modal-export-csv')
			this.$http.post('/admin/export/user-login/export', exportParam).then(response => {
				if(response) {
					// this.$swal({
					// 	title: 'Sukses',
					// 	icon: 'success',
					// 	text: 'Mengexport data di background, mohon tunggu beberapa saat.',
					// 	type: 'warning',
					// 	customClass: {
					// 		confirmButton: 'btn btn-primary',
					// 	},
					// 	buttonsStyling: false,
					// }) 
					console.log("success")
					// this.isLoadingExport = false
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validatiosns
				}
				return errorNotification(this, 'error Export', "Terjadi Kesalah Saat Export")	
			})
		},
		// getListAge() {
		// 	this.$http.get('/admin/list-range-umur')
		// 	.then(response => {
		// 		this.listAge = response.data.data
		// 	})
		// },
		// getProvince() {
		// 	this.$http.get('/admin/util/province/1')
		// 	.then(response => {
		// 		this.listProvince = response.data.data
		// 	})
		// },
		// getCity(data) {
		// 	this.$http.get(`/admin/util/city/${data}`)
		// 	.then(response => {
		// 		this.listCity = response.data.data
		// 	})
		// },
		getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
    		queryParams.page = page
			this.$http.get('/admin/users-activity', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.user = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
				const result = error.response.data.meta.validations
				for (const x in result) {
					errorNotification(this, `error ${x}`, result[x][0])
				}
			})	
		},
		// deleteItem(slug) {
		// 	this.$swal({
		// 		title: 'Are you sure?',
		// 		text: 'Are you sure to delete this user?',
		// 		icon: 'warning',
		// 		showCancelButton: true,
		// 		customClass: {
		// 		confirmButton: 'btn btn-primary',
		// 		cancelButton: 'btn btn-outline-danger ml-1',
		// 		},
		// 		buttonsStyling: false,
		// 	}).then(result => {
		// 		if (result.value) {
		// 		this.isLoading = true
		// 		this.$http.delete(`/admin/user/${slug}`)
		// 			.then(response => {
		// 			this.getData(this.currentPage)
		// 			this.$swal({
		// 				icon: 'success',
		// 				title: 'Success!',
		// 				text: 'User successfully deleted',
		// 				customClass: {
		// 				confirmButton: 'btn btn-success',
		// 				},
		// 			})
		// 			this.isLoading = false
		// 			})
		// 			.catch(error => {
		// 			if (error.response.data.meta.messages.length > 0) {
		// 				errorNotification(this, 'Oops!', error.response.data.meta.messages)
		// 			}
		// 			})
		// 		}
		// 	})
		// },
		formatDate(dateStr) {
			const date = new Date(dateStr);
			const options = { day: 'numeric', month: 'long', year: 'numeric' };
			return date.toLocaleDateString('id-ID', options);
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>