<template>
	<b-modal
		id="form-modal-export-csv"
		title="Export"
		hide-footer
		size="lg"
	>
		<form>
			<b-col cols="12" class="text-center mb-2">
				<button
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
					@click.prevent="exportNow"
				>
					Export Sekarang
				</button>
			</b-col>

			<b-table 
				striped 
				hover 
				:items="resultExportTable" 
				:fields="tableExport"
				:busy="isLoadingComponent"
				show-empty
				responsive
			>
				<template #thead-top>
					<tr>
						<th variant="primary" colspan="12" class="text-center">Export Log</th>
					</tr>
				</template>

				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				
				<template v-slot:cell(no)="{ index }">
					{{ index + 1 }}
				</template>

				<template v-slot:cell(path)="{ item }">
					<a v-if="/\.xlsx$/i.test(item.path)" class="btn btn-primary btn-sm" :href="item.path">Download Xlsx</a>
				</template>
				<template v-slot:cell(path_csv)="{ item }">
					<a v-if="/\.csv$/i.test(item.path_csv)" class="btn btn-primary btn-sm" :href="item.path_csv">Download Csv</a>
				</template>
			</b-table>
		</form>
	</b-modal>
</template>

<script>
import { 
	BTable,
	BSpinner,
	VBModal,
	BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
	components: {
		BTable,
		BSpinner,
		BCol,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	props: {
		resultExport: {
			type: '',
		},
		isLoading: {
			type: Boolean,
		},
		exportNow: {
			type: Function,
		},
	},
	watch: {
		isLoading(value) {
			this.isLoadingComponent = value
		},
		resultExport(value) {
			this.resultExportTable = value
		},
	},
	data() {
		return {
			isLoadingComponent: true,
			resultData:{},
			resultExportTable: [],
			tableExport: ['no', 'generated_by', 'export_time', 'note', 'path','path_csv'],
			path: process.env.URL_LANDING_PAGE
		}
	},
	computed: {
		rows() {
			return this.resultExportTable.length
		}
	},
}
</script>